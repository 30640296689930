import React from "react";
import { useRouteError } from "react-router-dom";
import styled from "@emotion/styled";
import { useSelector } from "react-redux";
import { selectDevices } from "Components/Devices/devicesSlice";
import { NavLink } from "react-router-dom";
import { useParams } from "react-router-dom";

const Container = styled.div`
  flex-grow: 1;
  overflow: auto;
  padding: 10px;
`;

const Link = styled(NavLink)`
  display: block;
  margin: 0.5em 1em;
`;

export default function Page404() {
  const error = useRouteError();
  const devices = useSelector(selectDevices);
  let { content } = useParams();

  return (
    <Container>
      <h1>404 Nie znaleziono strony</h1>
      <p>Strona o podanym linku nie została odnaleziona</p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
      <div>
        <p>Proponowane strony:</p>
        {devices.map((d) => (
          <Link key={d.id} to={`/mapa/${d.slug}/${content}`}>
            {d.label}
          </Link>
        ))}
      </div>
    </Container>
  );
}
