import React from "react";
import { getCaqiDescription } from "Utils/functions";
import { getCaqiColors } from "Utils/colors";
import styled from "@emotion/styled";
import CaqiIcon from "Components/CaqiIcon";
import Paper from "@mui/material/Paper";
import { getIndexMsg } from "Utils/pollutionInfo";

const Card = styled(Paper)`
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  text-justify: inter-word;
`;

const Msg = styled.div`
  font-size: 0.85em;
  padding: 10px 10px 3px;
  color: #666;
  text-align: justify;
`;

const Sensor = styled.div`
  text-align: right;
  font-size: small;
  color: #33869a;
  margin-top: 3px;
  margin-right: 8px;
  margin-bottom: 10px;
  & a {
    color: #33869a;
    &:hover {
      color: #33869a;
    }
  }
`;

const Widget = styled.div`
  width: 100%;
  height: 140px;
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.45) 100%
  );
`;

const CaqiVal = styled.div`
  position: absolute;
  bottom: 55px;
  left: 20px;
  color: #fff;
  font-size: 3em;
  font-weight: 600;
  line-height: 0.5;
  text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.3);
  & span {
    font-size: 0.4em;
  }
`;

const DeviceLabel = styled.div`
  position: absolute;
  left: 20px;
  top: 8px;
  font-size: 1.25em;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #fff;
  line-height: 1.5;
  text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.3);
`;

const LevelDescription = styled.div`
  position: absolute;
  left: 16px;
  bottom: 12px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
  line-height: 1;
  color: #fff;
  & strong {
    font-weight: 500;
  }
`;

const Circle1 = styled.div`
  position: absolute;
  background: #fff;
  border-radius: 50%;
  opacity: 0.1;
  width: 100px;
  height: 100px;
  left: 50px;
  top: 0;
  filter: blur(2px);
`;

const Circle2 = styled.div`
  position: absolute;
  background: #fff;
  border-radius: 50%;
  opacity: 0.1;
  width: 200px;
  height: 200px;
  top: 57px;
  right: -70px;
  filter: blur(8px);
`;

const ImgCover = styled.div`
  background: url("/jezioro.jpg");
  background-size: cover;
  background-position: center center;
  opacity: 0.15;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
`;

const iconStyle = {
  position: "absolute",
  top: 4,
  right: 4,
  fontSize: "4em",
  opacity: 0.4,
  color: "#fff",
};

function AirWidget(props) {
  const { label, caqi, link, info } = props.device;
  const style = { ...getCaqiColors(caqi) };
  const idxMsg = getIndexMsg(caqi);

  return (
    <Card elevation={2} style={props.style}>
      <Widget style={style}>
        <ImgCover />
        <Circle1 />
        <Circle2 />
        <DeviceLabel>{label}</DeviceLabel>
        <CaqiVal>
          {caqi} <span>CAQI</span>
        </CaqiVal>
        <LevelDescription>
          Poziom zanieczyszczeń: <strong>{getCaqiDescription(caqi)}</strong>
        </LevelDescription>
        <CaqiIcon caqi={caqi} style={iconStyle} />
      </Widget>
      <Msg dangerouslySetInnerHTML={{ __html: idxMsg }} />
      <Sensor>
        {link ? (
          <a href={link} target="_blank" rel="noopener noreferrer">
            {info}
          </a>
        ) : (
          <>{info}</>
        )}
      </Sensor>
    </Card>
  );
}

export default AirWidget;
