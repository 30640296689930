import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "readings",
  initialState: [],
  reducers: {
    setReadings: (state, action) => {
      return action.payload;
    },
  },
});

export const { setReadings } = slice.actions;
export const selectReadings = (state) => state.readings;
export default slice.reducer;
