export function repairData24(rawData) {
  const data = [...rawData];
  const timestamp = Math.floor(Date.now() / 1000);
  let startTime = getFirstTime(timestamp);
  if (!data.find((el) => el.time === startTime)) {
    let firstEl = getFirstReading(data);
    if (firstEl && firstEl.caqi) {
      data.push({
        time: startTime,
        caqi: firstEl.caqi,
      });
    }
  }

  let output = [];
  let prevNext = false;
  for (let i = 0; i < 96; i++) {
    let time = startTime + i * 15 * 60;
    let el = data.find((el) => el.time === time);
    if (el) {
      output.push(el);
    } else {
      prevNext = getPrevNextReading(data, time);
      output.push(interpolateReading(prevNext, time));
    }
  }
  return output;
}

export function interpolateReading(prevNext, timestamp) {
  // Zwraca uśrednoną wartość caqi i pm10
  let d = prevNext.next.time - prevNext.prev.time;
  let fPrev = (prevNext.next.time - timestamp) / d;
  let fNext = 1 - fPrev;
  let caqi =
    Math.round(10 * (prevNext.prev.caqi * fPrev + prevNext.next.caqi * fNext)) /
    10;
  return {
    time: timestamp,
    caqi,
  };
}

export function getFirstReading(data) {
  // Zwraca pierwszy znaleziony rekord
  let out = data[0];
  data.forEach((el) => {
    if (el.time < out.time) {
      out = el;
    }
  });
  return out;
}

export function getPrevNextReading(data, timestamp) {
  // Zwraca wartości poprzedniego i następnego dostępnego odczytu
  let prev = { time: 0, caqi: 0 };
  let next = { time: 2147483648, caqi: 0 };

  data.forEach((el) => {
    if (el.time < timestamp && el.time > prev.time) {
      prev = el;
    }
    if (el.time > timestamp && el.time < next.time) {
      next = el;
    }
  });
  return { prev, next };
}

export function getFirstTime(timestamp) {
  // Zwraca timestamp pierwszego odczytu
  let interval = 1000 * 60 * 15;
  let date = new Date(timestamp * 1000);
  return (
    toTimestamp(
      new Date(Math.floor(date.getTime() / interval) * interval - interval)
    ) -
    60 * 15 * 94
  );
}

// Convert string to date
export function toTimestamp(strDate) {
  return parseInt(Date.parse(strDate) / 1000);
}

// Test interpolateReading
//console.log(interpolateReading({prev:{time: 0, caqi: 10, pm10: 20}, next: {time: 100, caqi: 20, pm10: 30}}, 15));
