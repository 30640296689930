import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "devices",
  initialState: [],
  reducers: {
    setDevices: (state, action) => {
      return action.payload;
    },
  },
});

export const { setDevices } = slice.actions;
export const selectDevices = (state) => state.devices;
export default slice.reducer;
