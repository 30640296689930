import * as React from "react";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import styled from "@emotion/styled";

const SmallTabs = styled(Tabs)`
  min-height: 32px;
  & .MuiTab-root {
    min-height: 32px;
    padding: 8px;
  }
`;

export default function DesktopMenu() {
  const { slug, content } = useParams();
  return (
    <Box sx={{ borderBottom: 1, borderColor: "divider", background: "#fff" }}>
      <SmallTabs value={content} variant="fullWidth" centered>
        <Tab
          label="Info"
          component={Link}
          to={`/mapa/${slug}/info`}
          value="info"
        />
        <Tab
          label="Raport"
          component={Link}
          to={`/mapa/${slug}/raport`}
          value="raport"
        />
      </SmallTabs>
    </Box>
  );
}
