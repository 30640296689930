import * as React from "react";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import InfoIcon from "@mui/icons-material/Info";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

export default function MobileMenu() {
  const { slug, content } = useParams();

  return (
    <BottomNavigation showLabels value={content}>
      <BottomNavigationAction
        label="Info"
        icon={<InfoIcon />}
        component={Link}
        to={`/mapa/${slug}/info`}
        value="info"
      />

      <BottomNavigationAction
        label="Mapa"
        icon={<LocationOnIcon />}
        component={Link}
        to={`/mapa/${slug}/mapa`}
        value="mapa"
      />

      <BottomNavigationAction
        label="Raport"
        icon={<AutoGraphIcon />}
        component={Link}
        to={`/mapa/${slug}/raport`}
        value="raport"
      />
    </BottomNavigation>
  );
}
