import { useEffect } from "react";
import api from "api.js";
import { useDispatch } from "react-redux";
import { setReadings } from "./readingsSlice";
import { getTimestamp } from "Utils/functions";

const apiOptions = {
  method: "get",
  url: "",
  withCredentials: true,
};

let active = true;
let lastUpdateTime = 0;
const updateTime = 1000 * 60 * 5;
window.onfocus = () => (active = true);
window.onblur = () => (active = false);

function Devices() {
  const dispatch = useDispatch();

  useEffect(() => {
    getReadings();
    const interval = setInterval(() => {
      active && getReadings();
    }, updateTime);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const getReadings = async () => {
    const time = getTimestamp();
    if (time - lastUpdateTime < updateTime / 1000 - 10) return;
    lastUpdateTime = time;
    try {
      const response = await api(apiOptions);
      if (response.status === 200) {
        const data = response.data;
        dispatch(setReadings(data));
      } else {
        // Todo: add notification
        console.log("Błąd pobierania danych. Spróbuj ponownie później.");
      }
    } catch (e) {
      // Todo: add error handler
      // handleError(e, "Błąd pobierania danych. Spróbuj ponownie później.");
      console.log(e);
    }
  };

  return "";
}

export default Devices;
