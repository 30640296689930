export const colors = [
  "#009d0f",
  "#48a449",
  "#74b64e",
  "#a1c04a",
  "#cfcb46",
  "#ddc141",
  "#ecb73c",
  "#ec933a",
  "#ed6e37",
  "#e94e3c",
  "#e52d41",
  "#c9264d",
  "#ad1f5a",
  "#902468",
  "#722a76",
  "#4a1b4d",
  "#000000",
];

export const textColors = [
  "#FFF",
  "#FFF",
  "#FFF",
  "#000",
  "#000",
  "#000",
  "#000",
  "#000",
  "#fff",
  "#fff",
  "#fff",
  "#fff",
  "#fff",
  "#fff",
  "#fff",
  "#fff",
  "#fff",
];
export const levelsCaqi = [
  12.5,
  19,
  25,
  37.5,
  50,
  62.5,
  75,
  81,
  87.5,
  93.5,
  100,
  112.5,
  125,
  150,
  185,
  250,
];
export const monthlyMP25Levels = [
  5,
  7.5,
  11,
  17,
  23,
  30,
  36,
  43,
  56,
  70,
  90,
  115,
  135,
  170,
  210,
  300,
];

export const inactiveColor = "#aaa";

export const inactiveTextColor = "#000";

export function getLevel(caqi) {
  if (caqi === undefined) return inactiveColor;
  let level = levelsCaqi.length;
  for (let i = levelsCaqi.length; i > -1; i--) {
    if (caqi <= levelsCaqi[i]) level = i;
  }
  return level;
}

export function getPM25MonthlyLevel(pm25) {
  if (pm25 === undefined) return inactiveColor;
  let level = monthlyMP25Levels.length;
  for (let i = monthlyMP25Levels.length; i > -1; i--) {
    if (pm25 <= monthlyMP25Levels[i]) level = i;
  }
  return level;
}

export function getCaqiColor(caqi) {
  return colors[getLevel(caqi)];
}

export function getMonthlyPM25Color(pm25) {
  return colors[getPM25MonthlyLevel(pm25)];
}

export function getCaqiColors(caqi) {
  if (caqi === undefined)
    return {
      backgroundColor: inactiveColor,
      color: inactiveTextColor,
    };
  const level = getLevel(caqi);
  return {
    backgroundColor: colors[level],
    color: textColors[level],
  };
}

export function getMonthlyPM25Colors(pm25) {
  if (pm25 === undefined)
    return {
      backgroundColor: inactiveColor,
      color: inactiveTextColor,
    };
  const level = getPM25MonthlyLevel(pm25);
  return {
    backgroundColor: colors[level],
    color: textColors[level],
  };
}
