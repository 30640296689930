import React from "react";
import styled from "@emotion/styled";
import FlutterDashIcon from "@mui/icons-material/FlutterDash";
import Paper from "@mui/material/Paper";

const Content = styled.div`
  text-align: center;
  & h3 {
    color: #953734;
    opacity: 0.75;
  }
`;

const Icon = styled(FlutterDashIcon)`
  font-size: 96px;
  color: #e36c09;
  line-height: 1;
`;

const IconWrap = styled(Paper)`
  border-radius: 15px;
  display: inline-flex;
  box-sizing: border-box;
  padding: 20px;
  justify-content: center;
  align-items: center;
  margin: 40px 0 30px;
`;

function NotActive() {
  return (
    <Content>
      <IconWrap>
        <Icon />
      </IconWrap>
      <h3>
        Przepraszamy, czujnik nieaktywny. Sprawdź ponownie później lub wybierz
        inny.
      </h3>
    </Content>
  );
}

export default NotActive;
