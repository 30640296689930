import { createSlice } from "@reduxjs/toolkit";
import cookies from "js-cookie";

export const slice = createSlice({
  name: "activeDevice",
  initialState: null,
  reducers: {
    setActiveDevice: (state, action) => {
      const id = action.payload.id;
      if (id) cookies.set("activeDevice", id, { expires: 365 });
      return action.payload;
    },
  },
});

export const { setActiveDevice } = slice.actions;
export const selectActiveDevice = (state) => state.activeDevice;
export default slice.reducer;
