import { useEffect } from "react";
import api from "api.js";
import { useDispatch } from "react-redux";
import { setDevices } from "Components/Devices/devicesSlice";
import { slugify } from "Utils/functions";

const apiOptions = {
  method: "get",
  url: "devices",
};

function Devices() {
  const dispatch = useDispatch();

  useEffect(() => {
    getDevices();
    const interval = setInterval(() => {
      getDevices();
    }, 60 * 60 * 6);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const fetchYearlyReport = async () => {
    const result = await fetch(`/year-report.json?ver=${new Date().getTime()}`);
    return result.status === 200 ? result.json() : null;
  };

  const getDevices = async () => {
    try {
      const response = await api(apiOptions);
      if (response.status === 200) {
        const yearlyReport = await fetchYearlyReport();
        const data = response.data.map((d) => {
          const label = d ? `${d.city} - ${d.name}` : "Unknown";
          const slug = slugify(d ? `${d.city} ${d.name}` : "Unknown");
          const report = yearlyReport.find((r) => r.id === d.id);
          const reportValid =
            report && report.monthsAvg && report.monthsAvg.length > 5;
          return {
            ...d,
            slug,
            label,
            report: reportValid ? report : null,
          };
        });
        dispatch(setDevices(data));
      } else {
        // Todo: add notification
        console.log("Błąd pobierania danych. Spróbuj ponownie później.");
      }
    } catch (e) {
      // Todo: add error handler
      // handleError(e, "Błąd pobierania danych. Spróbuj ponownie później.");
      console.log(e);
    }
  };

  return "";
}

export default Devices;
