import React from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "Components/Sidebar";
import MapPage from "Pages/MapPage";
import styled from "@emotion/styled";
import { isMobile } from "config";
import Theme from "Components/Theme";
import { useSelector } from "react-redux";
import { selectDevices } from "Components/Devices/devicesSlice";
import { useParams, Navigate } from "react-router-dom";
import ActiveDevice from "Components/ActiveDevice";
import cookies from "js-cookie";

const mobile = isMobile();

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: row;
  background-color: rgb(236, 235, 224);
  font-family: "Tilt Neon", cursive;
`;

export default function LayoutPage() {
  const devices = useSelector(selectDevices);
  const { slug, content } = useParams();

  const redirectToDefault = () => {
    if (!devices.length) return;
    const activeDeviceId = parseInt(cookies.get("activeDevice") || 0);
    let defaultDevice;
    if (activeDeviceId)
      defaultDevice = devices.find((d) => d.id === activeDeviceId);
    if (!defaultDevice) defaultDevice = devices.find((d) => d.default);
    if (!defaultDevice) defaultDevice = devices[0];
    const newSlug = slug ? slug : defaultDevice.slug;
    return <Navigate to={`/mapa/${newSlug}/info`} />;
  };

  if (!slug || !content) return redirectToDefault();

  return (
    <Theme>
      <ActiveDevice />
      <Container>
        <Sidebar>
          <Outlet />
        </Sidebar>
        {!mobile && <MapPage sx={{ height: "100vh" }} />}
      </Container>
    </Theme>
  );
}
