import React from "react";
import LevelIcon1 from "@mui/icons-material/SentimentVerySatisfied";
import LevelIcon2 from "@mui/icons-material/SentimentSatisfiedAlt";
import LevelIcon3 from "@mui/icons-material/SentimentSatisfied";
import LevelIcon4 from "@mui/icons-material/SentimentNeutral";
import LevelIcon5 from "@mui/icons-material/SentimentDissatisfied";
import LevelIcon6 from "@mui/icons-material/SentimentVeryDissatisfied";
import LevelIcon7 from "@mui/icons-material/MoodBad";
import LevelIcon8 from "@mui/icons-material/Sick";

function CaqiIcon(props) {
  const { caqi, style, className } = props;
  if (caqi < 12.5) return <LevelIcon1 style={style} className={className} />;
  if (caqi < 25) return <LevelIcon2 style={style} className={className} />;
  if (caqi < 50) return <LevelIcon3 style={style} className={className} />;
  if (caqi < 75) return <LevelIcon4 style={style} className={className} />;
  if (caqi < 87.5) return <LevelIcon5 style={style} className={className} />;
  if (caqi < 100) return <LevelIcon6 style={style} className={className} />;
  if (caqi < 125) return <LevelIcon7 style={style} className={className} />;
  return <LevelIcon8 style={style} />;
}

export default CaqiIcon;
