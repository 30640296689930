import React, { useState, useEffect, useRef } from "react";
import styled from "@emotion/styled";
import { OverlayView } from "@react-google-maps/api";
import { useSelector } from "react-redux";
import { useParams, NavLink } from "react-router-dom";
import { selectReadings } from "Components/Readings/readingsSlice";
import { selectDevices } from "Components/Devices/devicesSlice";
import { getCaqiColors } from "Utils/colors";

const Device = styled(NavLink)`
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  padding: 4px;
  width: 10px;
  height: 10px;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  display: block;
  cursor: pointer;
  &:after {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.25) 100%
    );
  }
`;

const positionOverlayView = (width, height) => ({
  x: -width / 2,
  y: -height / 2,
});

function Marker(props) {
  const { lat, lng, caqi, current, slug } = props.device;
  const { content, centerMap } = props;
  const colors = getCaqiColors(caqi);
  const currentStyle = {
    width: 20,
    height: 20,
    boxShadow: `0 0 20px 10px ${colors.backgroundColor}`,
    zIndex: 2,
    marginTop: -5,
    marginLeft: -5,
    animationName: "pulse_animation",
    animationDuration: "2000ms",
    transformOrigin: "70% 70%",
    animationIterationCount: "infinite",
    animationTimingFunction: "linear",
  };
  const style = {
    ...colors,
    boxShadow: `0 0 20px 5px ${colors.backgroundColor}`,
    ...(current ? currentStyle : {}),
  };
  const slugRef = useRef(false);

  useEffect(() => {
    if (!current || slugRef.current) return;
    slugRef.current = true;
    centerMap(props.device);
  }, [current]);

  if (caqi === undefined) {
    style.boxShadow = "none";
  }
  const position = { lat, lng };

  const onClick = () => {
    slugRef.current = true;
  };

  return (
    <OverlayView
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      position={position}
      getPixelPositionOffset={positionOverlayView}
      onClick={onClick}
    >
      <Device style={style} to={`/mapa/${slug}/${content}`} onClick={onClick} />
    </OverlayView>
  );
}

function Markers(props) {
  const { slug, content } = useParams();
  const readings = useSelector(selectReadings);
  const devices = useSelector(selectDevices);
  const [data, setData] = useState([]);
  const { centerMap } = props;

  useEffect(() => {
    const data = devices.map((d) => {
      const res = { ...d };
      const r = readings.find((r) => r.id === d.id);
      if (r) {
        res.caqi = r.caqi;
        res.pm10 = r.pm10;
        res.pm25 = r.pm25;
        res.current = d.slug === slug;
      }
      return res;
    });
    setData(data);
  }, [readings, devices, slug]);

  return data.map((device) => (
    <Marker
      key={device.id}
      device={device}
      content={content}
      slug={slug}
      centerMap={centerMap}
    />
  ));
}

export default Markers;
