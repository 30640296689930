import React from "react";
import styled from "@emotion/styled";
import Menu from "Components/Menu";
import { isMobile } from "config";

const mobile = isMobile();
const sidebarWidth = 350;

const SidebarComponent = styled.div`
  width: 100%;
  height: 100vh;
  max-width: ${mobile ? "100%" : sidebarWidth + "px"};
  display: flex;
  flex-direction: ${mobile ? "column" : "column-reverse"};
  border-right: 1px solid #bbb;
`;

const SidebarContainer = styled.div`
  flex-grow: 1;
  position: relative;
  display: flex;
`;

function Sidebar(props) {
  return (
    <SidebarComponent sx={{ width: mobile ? "100%" : sidebarWidth }}>
      <SidebarContainer>{props.children}</SidebarContainer>
      <Menu />
    </SidebarComponent>
  );
}

export default Sidebar;
