// angular\src\app\map-panel\details\details.component.html

export const messages = [
  "Można przebywać na powietrzu przez dowolnie długi okres czasu :)",
  "Jakość powietrza jest uznawana za zadowalającą, a zanieczyszczenie powietrza stanowi niewielkie ryzyko dla zdrowia lub jego brak. Osoby wrażliwe mogą odczuwać problemy z oddychaniem",
  "Jakość powietrza jest dopuszczalna. Należy jednak ograniczyć czas przebywania na powietrzu, zwłaszcza przez kobiety w&nbsp;ciąży, dzieci i&nbsp;osoby starsze oraz przez osoby z&nbsp;astmą, chorobami alergicznymi skóry, oczu i&nbsp;chorobami krążenia",
  "Powietrze niezdrowe dla osób wrażliwych. Należy ograniczyć czas przebywania na powietrzu, zwłaszcza przez kobiety w&nbsp;ciąży, dzieci i&nbsp;osoby starsze oraz przez osoby z astmą, chorobami alergicznymi skóry, oczu i&nbsp;chorobami krążenia",
  "Powietrze niezdrowe. Każdy może zacząć doświadczać negatywnych skutków zdrowotnych; U&nbsp;osób wrażliwych mogą wystąpić poważniejsze skutki zdrowotne. Zaleca się unikanie lub ograniczenie do minimum czasu przebywania na powietrzu, zwłaszcza przez kobiety w&nbsp;ciąży, dzieci i&nbsp;osoby starsze oraz przez osoby z&nbsp;astmą, chorobami alergicznymi skóry, oczu i&nbsp;chorobami krążenia. Należy unikać dużych wysiłków fizycznych na otwartym powietrzu",
  "Powietrze bardzo niezdrowe. Mogą wystąpić poważne skutki zdrowotne całej populacji. Zaleca się unikanie lub ograniczenie do minimum czasu przebywania na powietrzu oraz stosowanie środków ochrony dróg oddechowych",
];
const levels = [25, 50, 75, 87.5, 100];

export function getIndexMsg(caqi) {
  if (caqi < levels[0]) {
    return messages[0];
  }
  if (caqi < levels[1]) {
    return messages[1];
  }
  if (caqi < levels[2]) {
    return messages[2];
  }
  if (caqi < levels[3]) {
    return messages[3];
  }
  if (caqi < levels[4]) {
    return messages[4];
  }
  return messages[5];
}
