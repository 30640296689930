import React, { useEffect, useState } from "react";
import Devices from "Components/Devices";
import Readings from "Components/Readings";
import { useSelector } from "react-redux";
import { selectDevices } from "Components/Devices/devicesSlice";
import { selectReadings } from "Components/Readings/readingsSlice";
import { useLoadScript } from "@react-google-maps/api";
// const isProduction = process.env.REACT_APP_ENV.trim() === "Production";
const mapLibraries = (process.env.REACT_APP_GOOGLE_LIBRARIES || "").split(",");
const googleMapsApiKey = process.env.REACT_APP_GOOGLE_KEY;

function App(props) {
  const { children } = props;
  const devices = useSelector(selectDevices);
  const readings = useSelector(selectReadings);
  const [active, setActive] = useState(false);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey,
    libraries: mapLibraries,
  });

  useEffect(() => {
    loadError && console.log(loadError);
  }, [isLoaded]);

  useEffect(() => {
    const dataAvailable = devices.length > 0 && readings.length > 0;
    if (dataAvailable && !active) setActive(true);
  }, [devices, readings]);

  if (!isLoaded) return "Ładuję mapę";
  return (
    <>
      <Devices />
      <Readings />
      {active && <>{children}</>}
      {!active && (
        <h3 style={{ textAlign: "center" }}>Wczytywanie danych ...</h3>
      )}
    </>
  );
}

export default App;
