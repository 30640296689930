import React from "react";
import styled from "@emotion/styled";

const Svg = styled.div`
  width: 100%;
  position: relative;
  display: block;
  box-sizing: border-box;
  overflow: hidden;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
`;

function SVG(props) {
  return <Svg dangerouslySetInnerHTML={{ __html: props.content }} {...props} />;
}

export default SVG;
