import React, { useState, useEffect, useRef } from "react";
import SVG from "Components/SVG";
import { drawCaqiSVG } from "./chart";
import styled from "@emotion/styled";
import { outputTimeDate, outputTime, getElementOffset } from "Utils/functions";
import Paper from "@mui/material/Paper";
import { repairData24 } from "./chart-interpolation";

const NotAvail = styled.div`
  width: 100%;
  height: 150px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-sizing: border-box;
  padding: 10px 20px;
  color: #666;
`;

const Chart = styled(Paper)`
  box-sizing: border-box;
  position: relative;
  color: #555;
`;

const MinMax = styled.div`
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  font-size: 0.85em;
  margin-bottom: 2px;
  line-height: 2;
  border-bottom: 1px solid #ccc;
  padding: 2px 12px;
`;

const MinMax2 = styled.div`
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  font-size: 0.85em;
  margin-bottom: 2px;
  line-height: 1.5;
  padding: 0 12px;
`;

const ChartTooltip = styled.div`
  position: relative;
`;

const Tooltip = styled.div`
  position: absolute;
  background: #fff;
  border-radius: 4px;
  padding: 4px;
  border: 1px solid #999;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
  opacity: 0;
  transition: opacity 0.5s;
  z-index: 2;
  font-size: 0.8em;
  width: 70px;
  height: 32px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  & i {
    font-style: normal;
    position: absolute;
    left: 6px;
    top: 3px;
  }
  & span {
    position: absolute;
    top: 20px;
    left: 13px;
  }
`;

function ChartCaqi(props) {
  const { data } = props;
  const data24 = data ? data.data24 : null;
  const [chart, setChart] = useState(null);
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(0);
  const [startTime, setStartTime] = useState(0);
  const [endTime, setEndTime] = useState(0);
  const [active, setActive] = useState(false);
  const [chartData, setChartData] = useState([]);
  const chartInfoRef = useRef({ left: 0, top: 0, width: 300 });
  const tooltipRef = useRef(null);
  const chartRef = useRef(null);

  useEffect(() => {
    if (!data24 || data24.length < 20) return;
    initData();
  }, [data24]);

  const initData = () => {
    setActive(true);
    const svg = drawCaqiSVG(data);
    setChartData(data24.length === 96 ? data24 : repairData24(data24));
    const min = data24.reduce(
      (res, val) => (val.caqi < res ? val.caqi : res),
      999999
    );
    const max = data24.reduce(
      (res, val) => (val.caqi > res ? val.caqi : res),
      0
    );
    const startTime = data24[0].time;
    const endTime = data24[data24.length - 1].time;
    setChart(svg);
    setMin(min);
    setMax(max);
    setStartTime(startTime);
    setEndTime(endTime);
  };

  const positionTooltip = (ev) => {
    const { left, top, width } = chartInfoRef.current;
    const x = Math.min(
      width - 60 - left,
      Math.max(-10, ev.clientX - left - 45)
    );
    const y = ev.clientY - top - 50;
    tooltipRef.current.style.left = x + "px";
    tooltipRef.current.style.top = y + "px";
  };

  const setTooltipValue = (ev) => {
    const { left, width } = chartInfoRef.current;
    const idx = Math.round(((ev.clientX - left) * 95) / width);
    const { caqi, time } = chartData ? chartData[idx] : { caqi: 0, time: 0 };
    const html = `<i>godz: ${outputTime(time)}</i><span>CAQI: ${caqi}</span>`;
    tooltipRef.current.innerHTML = html;
  };

  const onMouseMove = (ev) => {
    positionTooltip(ev);
    setTooltipValue(ev);
  };

  const onMouseEnter = (ev) => {
    const width = ev.target.offsetWidth;
    chartInfoRef.current = { ...getElementOffset(chartRef.current), width };
    tooltipRef.current.style.opacity = 1;
  };

  const onMouseLeave = () => {
    tooltipRef.current.style.opacity = 0;
  };

  return (
    <div>
      {active && (
        <Chart elevation={2}>
          <MinMax>
            <div>min: {min}</div>
            <div>CAQI z ostatnich 24h</div>
            <div>max: {max}</div>
          </MinMax>
          <ChartTooltip ref={chartRef}>
            <SVG
              content={chart}
              onMouseMove={onMouseMove}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
            />
            <Tooltip ref={tooltipRef}>Tooltip</Tooltip>
          </ChartTooltip>

          <MinMax2>
            <div>{outputTimeDate(startTime)}</div>
            <div>{outputTimeDate(endTime)}</div>
          </MinMax2>
        </Chart>
      )}
      {!active && (
        <NotAvail>
          Przepraszamy wykres niedostępny. Proszę spróbować później
        </NotAvail>
      )}
    </div>
  );
}

export default ChartCaqi;
