import React, { useRef, useCallback } from "react";
import { GoogleMap } from "@react-google-maps/api";
import Markers from "./Markers";
import "./map.css";
import { isMobile } from "config";
import styled from "@emotion/styled";

const mobile = isMobile();

const Map = styled.div`
  width: 100%;
  height: ${mobile ? "calc(100% - 80px)" : "100%"};
  position: relative;
  overflow: hidden;
`;

const Logo = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  img {
    width: 210px;
  }
`;

const mapContainerStyle = {
  width: "100%",
  height: "100%",
};

const mapOptions = {
  zoomControl: mobile ? false : true,
  mapTypeControl: mobile ? false : true,
  scaleControl: false,
  streetViewControl: false,
  rotateControl: false,
  fullscreenControl: false,
  disableDoubleClickZoom: true,
  clickableIcons: false,
  mapTypeId: "roadmap", // roadmap, satellite, hybrid, terrain
  mapTypeControlOptions: {
    style: 1, // window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR
    position: 2, // window.google.maps.ControlPosition.TOP_CENTER
  },
};

function MapPage(props) {
  const mapRef = useRef();
  const zoomRef = useRef(13);
  const centerRef = useRef({ lat: 52.410075, lng: 17.07738 });

  const centerMap = (device) => {
    const { lat, lng } = device;
    mapRef.current.setCenter({ lat, lng });
  };

  const onMapLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);

  const mapStyle = props.active || !mobile ? "block" : "none";
  return (
    <Map style={{ display: mapStyle }}>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={zoomRef.current}
        center={centerRef.current}
        options={mapOptions}
        onLoad={onMapLoad}
        className="map"
      >
        <Markers centerMap={centerMap} />
      </GoogleMap>
      <Logo>
        <img src="/logo.png" alt="Czysty Swarzędz logo" />
      </Logo>
    </Map>
  );
}

export default MapPage;
