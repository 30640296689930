import { configureStore } from "@reduxjs/toolkit";
import devicesSlice from "./Components/Devices/devicesSlice";
import readingsSlice from "./Components/Readings/readingsSlice";
import activeDeviceSlice from "Components/ActiveDevice/activeDeviceSlice";

export default configureStore({
  reducer: {
    devices: devicesSlice,
    readings: readingsSlice,
    activeDevice: activeDeviceSlice,
  },
});
