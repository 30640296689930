import React from "react";
import styled from "@emotion/styled";
import { useSelector } from "react-redux";
import { selectDevices } from "Components/Devices/devicesSlice";
import { NavLink } from "react-router-dom";
import { useParams } from "react-router-dom";
import { red } from "@mui/material/colors";
import { Paper } from "@mui/material";

const Container = styled(Paper)`
  padding: 10px;
`;

const Link = styled(NavLink)`
  display: block;
  margin: 0.5em 1em;
`;

const Title = styled.div`
  font-size: 1.5em;
  color: ${red[900]};
`;

const allowedContent = ["info", "raport", "mapa"];

export default function UnknownDevice() {
  const devices = useSelector(selectDevices);
  let { content } = useParams();
  if (allowedContent.indexOf(content) === -1) content = allowedContent[0];

  return (
    <Container>
      <Title>Nieznany czujnik</Title>
      <div>
        <p>Spróbuj wybrać inny czujnik z listy:</p>
        {devices.map((d) => (
          <Link key={d.id} to={`/mapa/${d.slug}/${content}`}>
            {d.label}
          </Link>
        ))}
      </div>
    </Container>
  );
}
