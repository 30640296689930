import * as React from "react";
import LocationPicker from "./LocationPicker";
import { isMobile } from "config";
import { useParams } from "react-router-dom";
import DesktopMenu from "./DesktopMenu";
import MobileMenu from "./MobileMenu";
import styled from "@emotion/styled";
import MobileWidget from "./MobileWidget";

const mobile = isMobile();

const MobileWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
`;

const DesktopWrapper = styled.div`
  width: 100%;
`;

const Wrapper = mobile ? MobileWrapper : DesktopWrapper;

export default function Menu() {
  const { slug, content } = useParams();

  if (!slug) return "404 location not found";
  return (
    <Wrapper>
      {!mobile && <DesktopMenu />}
      {mobile && content === "mapa" && <MobileWidget />}
      <LocationPicker />
      {mobile && <MobileMenu />}
    </Wrapper>
  );
}
