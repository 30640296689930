import React, { useEffect, useState } from "react";
import InfoPage from "Pages/InfoPage";
import ReportPage from "Pages/ReportPage";
import MapPage from "Pages/MapPage";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectDevices } from "Components/Devices/devicesSlice";
import styled from "@emotion/styled";
import UnknownDevice from "./UnknownDevice";
import { Navigate } from "react-router-dom";
import { isMobile } from "config";

const mobile = isMobile();

const Content = styled.div`
  height: calc(100vh - 120px);
  overflow: auto;
  width: 100%;
  position: relative;
  padding-bottom: 4px;
  @media screen and (max-width: 767px) and (orientation: landscape) {
    height: calc(100vw - 120px);
    width: 100vh;
  }
`;

const allowedContent = mobile ? ["info", "raport", "mapa"] : ["info", "raport"];

function SidebarContent() {
  const devices = useSelector(selectDevices);
  let { slug, content } = useParams();
  const [unknownDevice, setUnknownDevice] = useState(false);
  const [showMap, setShowMap] = useState(false);

  useEffect(() => {
    const device = devices.find((d) => d.slug === slug);
    if (!device) {
      setUnknownDevice(true);
    } else {
      setUnknownDevice(false);
    }
  }, [slug]);

  if (unknownDevice) {
    return (
      <Content>
        <UnknownDevice />
      </Content>
    );
  }

  if (allowedContent.indexOf(content) === -1) {
    return <Navigate to={`/mapa/${slug}/${allowedContent[0]}`} />;
  }

  if (content === "mapa" && !showMap) setShowMap(true);

  return (
    <Content>
      {content === "info" && <InfoPage />}
      {content === "raport" && <ReportPage />}
      {(content === "mapa" || showMap) && (
        <MapPage active={content === "mapa"} />
      )}
    </Content>
  );
}

export default SidebarContent;
