import React from "react";
import Page404 from "Pages/Page404";
import { createBrowserRouter } from "react-router-dom";
import SidebarContent from "Pages/SidebarContent";
import Layout from "Pages/LayoutPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: <Page404 />,
    children: [
      {
        path: "mapa/:slug/:content",
        element: <SidebarContent />,
      },
      {
        path: "mapa/:slug",
        element: <SidebarContent />,
      },
      {
        path: "mapa",
        element: <SidebarContent />,
      },
    ],
  },
]);

export default router;
