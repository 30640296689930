import { repairData24 } from "./chart-interpolation";
import { getCaqiColor } from "Utils/colors";

let now = new Date();
let timeOffset = now.getTimezoneOffset();

const deepCopyObj = (obj) => JSON.parse(JSON.stringify(obj));

export function drawCaqiSVG(data) {
  let data1 = deepCopyObj(data);
  if (data1.data24) {
    let data = [];
    // let smoothChart = data1.pm25temp;
    let smoothChart = true;
    let zoom;
    let maxZoom = 1.5;
    let minZoom = 0.2;
    let chartHeight = 80;
    let maxCaqi = data1.data24.reduce((res, val) => (val > res ? val : res), 0);
    zoom = chartHeight / maxCaqi;
    if (data1.data24.length < 96) {
      data1.data24 = repairData24(data1.data24);
    }
    if (smoothChart) {
      let maxCaqi = 0;
      for (let i = 0; i < data1.data24.length; i++) {
        let y = data1.data24[i].caqi;
        let dat = {};
        let j = 1;
        if (i > 0) {
          j++;
          y += data1.data24[i - 1].caqi;
        }
        if (i < data1.data24.length - 1) {
          j++;
          y += data1.data24[i + 1].caqi;
        }
        dat.time = data1.data24[i].time;
        dat.caqi = Math.round((10 * y) / j) / 10;
        maxCaqi = Math.max(dat.caqi, maxCaqi);
        data[i] = dat;
      }
      zoom = chartHeight / maxCaqi;
    } else {
      data = data1.data24;
    }
    data = data1.data24;
    zoom = Math.min(zoom, maxZoom);
    zoom = Math.max(zoom, minZoom);
    let redLine = chartHeight - 25 * zoom;
    let svg = '<?xml version="1.0" encoding="utf-8"?>\n';
    svg +=
      '<svg id="chart-caqi" style="background:#fff;" viewBox="0 0 192 80" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">\n';
    for (let i = 0; i < 96; i++) {
      if (data[i]) {
        let val = data[i].caqi;
        let x = i * 2 + 1;
        let y = chartHeight - val * zoom;
        let color = getCaqiColor(val);
        svg += `<line x1="${x}" y1="80" x2="${x}" y2="${y}" style="stroke:${color};stroke-width:2.5" />\n`;
      }
    }
    for (let i = 0; i < 96; i++) {
      if (data[i]) {
        let x = i * 2 + 1;
        let time = getTime(data[i].time + timeOffset * 60);
        if (data[i].time % 7200 === 0) {
          svg += `<line x1="${x - 0.5}" y1="80" x2="${x -
            0.5}" y2="20" style="stroke:rgba(0,0,0,0.1);stroke-width:1" />\n`;
        }
        if (data[i].time % 14400 === 0) {
          svg += `<text x="${x -
            0.5}" y="10" fill="#333" style="font-size: 8px;" text-anchor="middle">${time}</text>`;
        }
        if (data[i].time % 28800 === 0) {
          svg += `<line  stroke-dasharray="2, 2" x1="${x -
            0.5}" y1="80" x2="${x -
            0.5}" y2="14" style="stroke:rgba(0,0,0,0.3);stroke-width:1" />\n`;
        }
      }
    }
    svg += `<line stroke-dasharray="2, 5"  x1="0" y1="40.5" x2="192" y2="40.5" style="stroke:rgba(0,0,0,0.2);stroke-width:1" />\n`;
    svg += `<line stroke-dasharray="2, 5"  x1="0" y1="${redLine}" x2="192" y2="${redLine}" style="stroke: #f00;stroke-width:1" />\n`;
    svg += "</svg>";

    return svg;
  }
}

function getTime(timestamp) {
  let date = new Date(timestamp * 1000);
  let hours = date.getHours() - date.getTimezoneOffset() / 60;
  hours = "" + hours;
  return hours + ":00";
}
