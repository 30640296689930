import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectDevices } from "Components/Devices/devicesSlice";
import { setActiveDevice } from "./activeDeviceSlice";
import { useDispatch } from "react-redux";
import { selectReadings } from "Components/Readings/readingsSlice";
import api from "api";

function ActiveDevice() {
  const devices = useSelector(selectDevices);
  const readings = useSelector(selectReadings);
  const dispatch = useDispatch();
  const { slug } = useParams();

  useEffect(() => {
    getDetails();
  }, [slug, devices, readings]);

  const deepCopyObj = (obj) => JSON.parse(JSON.stringify(obj));

  const getDetails = async () => {
    const device = devices.find((d) => d.slug === slug);
    if (!device) return;
    const { id } = device;
    const apiOptions = {
      method: "get",
      url: `${id}`,
    };

    try {
      const response = await api(apiOptions);
      if (response.status === 200) {
        const { data } = response;
        if (data.data24) data.data24.reverse();
        const activeDevice = deepCopyObj({ ...device, ...data });
        dispatch(setActiveDevice(activeDevice));
      } else {
        // Todo: add notification
        console.log("Błąd pobierania danych. Spróbuj ponownie później.");
      }
    } catch (e) {
      // Todo: add error handler
      // handleError(e, "Błąd pobierania danych. Spróbuj ponownie później.");
      console.log(e);
    }
  };

  return "";
}

export default ActiveDevice;
