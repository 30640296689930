import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectDevices } from "Components/Devices/devicesSlice";
import { selectReadings } from "Components/Readings/readingsSlice";
import { getCaqiColors } from "Utils/colors";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { sortAlpha } from "Utils/functions";

const ColorBox = styled.i`
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  border-radius: 3px;
`;

const Link = styled(NavLink)`
  text-decoration: none;
  color: #333;
`;

const Box = styled.div`
  background: #fff;
  margin: 16px 10px 10px;
  .MuiInputBase-root {
    i {
      vertical-align: sub;
    }
  }
`;

export default function LocationPicker() {
  const devices = useSelector(selectDevices);
  const readings = useSelector(selectReadings);
  const { slug, content } = useParams();
  const [device, setDevice] = useState(null);
  const [data, setData] = useState(null);

  useEffect(() => {
    updateDevices();
  }, [devices, readings, slug]);

  const updateDevices = () => {
    const data = devices.map((device) => {
      const reading = readings.find((reading) => reading.id === device.id);
      if (!reading || reading.caqi === undefined)
        return { ...device, active: false, ...getCaqiColors() };
      return {
        ...device,
        ...reading,
        active: true,
        ...getCaqiColors(reading.caqi),
      };
    });
    const device = data.find((d) => d.slug === slug);
    if (device) {
      setDevice(device);
    } else {
      setDevice(null);
    }
    setData(sortAlpha(data, "label"));
  };

  if (!data) return "";
  return (
    <Box>
      <FormControl fullWidth>
        <InputLabel id="select-device">Wybierz czujnik</InputLabel>
        <Select
          labelId="select-device"
          value={device.id}
          label="Wybierz czujnik"
          size="small"
        >
          {data.map((d) => {
            const { color, backgroundColor, slug } = d;
            const style = { color, backgroundColor };
            const link = `mapa/${slug}/${content}`;
            return (
              <MenuItem key={d.id} value={d.id} component={Link} to={link}>
                <ColorBox style={style} />
                {d.label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
}
